<template>
  <div class="gc-000 personal">
    <div class="g-flex">
      <div style="height: calc(100vh - 130px);" class="gw-300p gb-fff gpv-15 gph-15 gmr-10">
        <div class="g-flex gbd-b gpb-20 gmb-10">
          <img :src="qiniuDomain+userInfo.head_img" class="gh-110p gw-100p gmr-15">
          <div class="g-flex gpv-5 g-flex-column g-justify-space-between">
            <div>
              <div class="gfs-20 gfw-b">{{userInfo.name}}</div>
              <div class="gc-aaa">{{userInfo.student_no}}</div>
            </div>
            <div>{{gradeList[userInfo.grade-1]}} / {{userInfo.class_name}}</div>
          </div>
        </div>
        <div class="tabBar">
          <div class="tabItem g-flex g-flex-c " :class="{active:curTab.indexOf('/personal/basic')!=-1}" @click="$router.push('/personal/basic')">基本资料</div>
          <div class="tabItem g-flex g-flex-c " :class="{active:curTab.indexOf('/personal/collect')!=-1}" @click="$router.push('/personal/collect')">我的收藏</div>
          <div class="tabItem g-flex g-flex-c " :class="{active:curTab.indexOf('/personal/security')!=-1}" @click="$router.push('/personal/security')">安全设置</div>
          <div class="tabItem g-flex g-flex-c " :class="{active:curTab.indexOf('/personal/vip')!=-1}" @click="$router.push('/personal/vip')">会员中心</div>
        </div>
      </div>
      <div style="height: calc(100vh - 130px);overflow-y: auto;" class="g-flex-1 gph-10 gpv-10 gb-fff">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { gradeList } from '@/utils/common'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      curTab: '',
      gradeList: JSON.parse(JSON.stringify(gradeList)),
    }
  },
  computed: {
    ...mapGetters(['qiniuDomain']),
    userInfo() {
      return this.$store.getters.userInfo
    },
  },
  mounted() {
    this.setTab()
  },
  methods: {
    setTab() {
      this.curTab = this.$route.path
    },
  },
  watch: {
    $route() {
      this.setTab()
    },
  },
}
</script>
<style lang="less" scoped>
.personal {
  .tabBar {
    .tabItem {
      padding: 10px 0;
      background: #fff;
      color: #000;
      cursor: pointer;
      &.active {
        background: #1890ff;
        color: #fff;
      }
    }
  }
}
</style>
